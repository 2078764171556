<template>
    <div class="modal profile_modal share_playbook" v-if="modelValue" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1 class="m-0 pb-4">Assigned User {{ `( ${activePage.title} )` }}</h1>
                <button class="close_btn" @click="closeModal()"><i class="fas fa-times"></i></button>
            </div>
            <div class="modal_body">
                <div class="tab_wpr mt-3">
                    <div class="result_wpr">
                        <div class="actions">
                            <ul>
                                <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                                    {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                                    <page-filter v-model="params.per_page" :type="2" :is-dropdown="true" ref="page-assigned-member-filter" />
                                </li>
                                <li><input type="text" class="p-0" @input="isTyping = true" v-model.trim="params.search" placeholder="Search"/>
                                    <i class="fas fa-search"></i>
                                </li>
                                <li class="list_info">
                                    {{ assignedMembers.from ? assignedMembers.from : 0 }} - {{ assignedMembers.to ? assignedMembers.to : 0 }} of <span>{{ assignedMembers.total ? assignedMembers.total : 0 }}</span>
                                </li>
                            </ul>
                        </div>
                        <div v-if="loader"><line-loader /></div>
                        <table class="standard">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th>Username</th>
                                    <th>Date</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody v-if="assignedMembers.data && assignedMembers.data.length">
                                <tr v-for="(member, m) in assignedMembers.data" :key="m">
                                    <td></td>
                                    <td>
                                        <div class="user_wpr">
                                            <h4>{{ member.contact ? member.contact.name : '-' }}</h4>
                                        </div>
                                    </td>
                                    <td><div class="email_wpr">{{ member.contact ? member.contact.email : '-' }}</div></td>
                                    <td>{{ member.created_at ? moment(member.created_at).format('ll') : '-' }}</td>
                                    <td>
                                        <ul class="action_list">
                                            <li @click="handleDeleteAssignedMember([member.id])"><i class="fas fa-trash-alt"></i></li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="3" class="px-4">No Records Found</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="pagination pb-4 mt-4" v-if="assignedMembers.data && assignedMembers.data.length">
                        <pagination v-model="params.page" :pages="assignedMembers.last_page" :range-size="0" @update:modelValue="handlePagination" />
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button class="btn cancel_btn" @click="closeModal()">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import {mapState, mapActions} from 'vuex';

    const PageFilter = defineAsyncComponent(() => import('@/components/PageFilter'))
    const LineLoader = defineAsyncComponent(() => import('@/components/LineLoader'))

    import moment from 'moment'
    import Pagination from '@hennge/vue3-pagination'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'


    export default {
        data () {
            return {
                params: {
                    per_page: 5,
                    page: 1,
                    search: '',
                    page_id: '',
                },
                moment,
                isTyping: false,
            }
        },

        props: {
            modelValue: Boolean,
            activePage: Object,
        },

        emit: ['update:modelValue'],

        watch: {
            modelValue (val) {
                const vm = this;

                if (val) {
                    vm.params = {
                        per_page: 5,
                        page: 1,
                        search: '',
                        id: vm.activePage.id,
                    };


                    vm.getAssignedMembers(vm.params);
                }
            },

            'params.per_page' () {
                const vm = this;

                vm.params.page = 1;
                vm.getAssignedMembers(vm.params);
            },

            'params.search' () {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500)
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length == 0 || vm.params.search.length >= 2) {
                            vm.params.page = 1;

                            vm.getAssignedMembers(vm.params);
                        }
                    }
                }
            },
        },

        components: {
            PageFilter,
            Pagination,
            LineLoader
        },

        computed: mapState({
            loader: state => state.pageModule.pageComponentLoader,
            assignedMembers: state => state.pageModule.assignedMembers,
        }),

        methods: {
            ...mapActions({
                getAssignedMembers: 'pageModule/getAssignedMembers',
                deleteAssignedMember: 'pageModule/deleteAssignedMember',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false)
            },

            togglePerPageFilter () {
                const vm = this;

                const filter = vm.$refs['page-assigned-member-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['page-assigned-member-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getAssignedMembers(vm.params);
            },

            handleDeleteAssignedMember (ids) {
                const vm = this;

                const option = Helper.swalConfirmOptions('Are you sure?', `You will not be able to recover these assigned ${ids.length > 1 ? 'members' : 'member'}`);

                option.preConfirm = function () {
                                        const params = { page_id: vm.activePage.id, ids }

                                        return vm.deleteAssignedMember(params).then((result) => {
                                            if (result) {
                                                vm.params.page = 1;
                                                vm.getAssignedMembers(vm.params);

                                                if (vm.$parent.$parent && vm.$parent.$parent.refreshPage) {
                                                    vm.$parent.$parent.refreshPage();
                                                } else {
                                                    vm.$parent.refreshPage();
                                                }
                                            }
                                        });
                                    };

                Swal.fire(option);
            },
        }
    }
</script>

<style scoped>
    .share_playbook .modal_container {
        height: 640px;
    }

    .share_playbook .modal_container .modal_header {
        padding: 20px 30px 0;
    }

    .share_playbook .modal_container .modal_body {
        padding: 0 30px;
        flex-direction: column;
    }

    .share_playbook .modal_body {
        padding: 0 30px;
        flex-direction: column;
    }

    .share_playbook .tab_row {
        padding: 20px 0 0 0;
        display: flex;
    }

    .share_playbook .tab_row li {
        padding: 8px 20px;
        position: relative;
        cursor: pointer;
    }

    .share_playbook .tab_row li.active {
        background: #eaeaea;
        border-radius: 5px 5px 0 0;
    }

    .share_playbook .tab_wpr {
        width: 100%;
    }

    .share_playbook .result_wpr {
        width: 100%;
    }

    .action_list {
        display: flex;
        flex-wrap: wrap;
    }

    .action_list li {
        margin-right: 5px;
        cursor: pointer;
    }

    .action_list li i.fa-trash-alt {
        color: #eb1414;
    }
</style>
